
import { Component, Vue, Prop } from "vue-property-decorator";

interface Marker {
  title: string;
  subTitle: string;
  description: string;
  position: Position;
}

interface Position {
  lat: number;
  lng: number;
}

@Component({})
export default class Map extends Vue {
  @Prop() private customClass!: string;

  private posLng = -1.623114;
  private posLat = 54.972405;

  private markerOption = {
    url: require("@/assets/page-decorators/leighton-pills-small.png"),
    size: { width: 60, height: 60, f: "px", b: "px" },
    scaledSize: { width: 60, height: 60, f: "px", b: "px" }
  };
  private markers = [
    {
      title: "How to find us!",
      subTitle: "Leighton",
      description: `
        The Core, Bath Lane,<br />
        Newcastle Helix,<br />
        Newcastle Upon Tyne NE4 5TF
        <span class="underline">0191 305 5140</span>
      `,
      position: {
        lat: this.posLat,
        lng: this.posLng
      }
    }
  ];
  private infoPosition = {
    lat: this.posLat,
    lng: this.posLng
  };
  private currentMidx = 0;
  private infoContent = "";
  private infoOpened = false;
  private infoCurrentKey = null;
  private infoOptions = {
    pixelOffset: {
      width: 190,
      height: 100
    }
  };

  mounted() {
    this.toggleInfoWindow(this.markers[0], 0);
  }

  private toggleInfoWindow(marker: Marker, idx: number): void {
    this.infoPosition = marker.position;
    this.infoContent = this.getInfoWindowContent(marker);

    //check if its the same marker that was selected if yes toggle
    if (this.currentMidx == idx) {
      this.infoOpened = !this.infoOpened;
    }
    //if different marker set infowindow to open and reset current marker index
    else {
      this.infoOpened = true;
      this.currentMidx = idx;
    }
  }

  private getInfoWindowContent(marker: Marker) {
    return `
      <div class="map-marker">
        <h3 class="map-marker--title">${marker.title}</h3>
        <p class="map-marker--subtitle">${marker.subTitle}</p>
        <p class="map-marker--description">${marker.description}</p>
      </div>
    `;
  }
}
