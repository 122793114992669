
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import MainBanner from "../components/MainBanner.vue";
import TitleText from "../components/TitleText.vue";
import Form from "@/components/Form.vue";
import Map from "../components/Map.vue";
import { GetInTouchForm as IGetInTouchForm } from "@/interfaces/GetInTouchForm.interface";
import ApiService from "@/services/ApiService";

@Component<any>({
  metaInfo() {
      return {
        title: "Contacting Leighton - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: `${ this.banner.text }`,
        }]
    }
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    Form,
    Map
  }
})
export default class GetInTouch extends Vue {
  private banner = {
    heading: "We love to talk and ask ",
    emphasis: "questions!",
    text:
      "Whether you want to accelerate your project or just start a conversation please get in touch.",
    action: {
      text: "Get in touch"
    },
    image: require("../assets/banner-images/contact.png")
  };

  private titleText1 = {
    customClass: "mt-md",
    subTitle: `
      <p>
        We’d love to hear from you if you would like to know more about any of our tech solutions, like our employee onboarding software. You might want to know more about how our custom software team can help you make money, save money, integrate systems and solve big business problems.
      </p>
      <p>
        It might be that you’re interested in our cost-effective talent services and how our dedicated teams or team augmentation can provide you with the skills you need, when you need them to deliver success.
      </p>
      <p>
        Or you might want to know more about life at Leighton and what it’s like to work here.
      </p>
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble3-medium.png"),
        type: "bubble-three d-none d-lg-block",
        top: "250px",
        left: "10px"
      },
      {
        image: require("@/assets/page-decorators/yellow-pill.png"),
        type: "yellow-pill behind d-none d-md-block",
        top: "250px",
        right: "1200px"
      },
      {
        image: require("@/assets/page-decorators/yellow-pill.png"),
        type: "yellow-pill behind d-md-none",
        top: "1150px",
        right: "240px"
      },
      {
        image: require("@/assets/page-decorators/bubble3-small.png"),
        type: "bubble-three",
        top: "550px",
        right: "1250px"
      }
    ]
  };

  private form = {
    success: false,
    error: false,
    popUpMsg: ``,
    customClass: "mt-md",
    formHeading: "Get in touch",
    gdprStatement:
      "This is the GDPR privacy part... If you’d like to see how we’re committed to your privacy here is our full Privacy Policy. By clicking submit below you are consenting to your personal data being processed and stored by Leighton Limited and for us to contact you.",
    formData: {
      name: {
        value: "",
        errMsg: "Please enter your name",
        error: false
      },
      email: {
        value: "",
        errMsg: "Please enter a valid email",
        error: false
      },
      phone: {
        value: "",
        errMsg: "Please enter a valid phone number",
        error: false
      },
      message: {
        value: "",
        errMsg:
          "Please enter a minimum of 10 characters and maximum of 4000 characters",
        error: false
      },
      subject: {
        value: "",
        errMsg: "Please select an item from the dropdown",
        error: false
      }
    },
    rows: [
      [
        {
          label: "Name",
          name: "name",
          type: "text",
          model: "name",
          required: true,
          disableSpace: false
        }
      ], //Row 1
      [
        {
          label: "Email Address",
          name: "email",
          type: "email",
          model: "email",
          required: true,
          disableSpace: true
        },
        {
          label: "Telephone Number",
          name: "phone",
          type: "tel",
          model: "phone",
          required: true,
          disableSpace: true
        }
      ], //row 2
      [
        {
          label: "Message",
          name: "message",
          type: "textarea",
          model: "message",
          required: true,
          rows: 5,
          cols: 50,
          disableSpace: false
        }
      ] //Row 3
    ]
  };

  private regex = /(<([^>]+)>)/gi;

  private async sendForm(formData: IGetInTouchForm) {
    formData.name.value = formData.name.value.replace(this.regex, "");
    formData.email.value = formData.email.value.replace(this.regex, "");
    formData.phone.value = formData.phone.value.replace(this.regex, "");
    formData.subject.value = 'Leighton Contact Form';
    formData.message.value = formData.message.value.replace(this.regex, "");

    // reset each error so that if its the users second attempt, the error does not stay visible upon success
        this.form.formData.name.error = false;
    this.form.formData.email.error = false;
    this.form.formData.phone.error = false;
    this.form.formData.subject.error = false;
    this.form.formData.message.error = false;
    
    await ApiService.post(
      "https://g623sdseed.execute-api.eu-west-2.amazonaws.com/dev/submitted",
      formData
    )
      .then(resp => {
        if (resp.has_error) {
          if (resp.messages.name.status === true) {
            this.form.formData.name.error = true;
          } else {
            this.form.formData.name.error = false;
          }
          if (resp.messages.email.status === true) {
            this.form.formData.email.error = true;
          } else {
            this.form.formData.email.error = false;
          }
          if (resp.messages.phone.status === true) {
            this.form.formData.phone.error = true;
          } else {
            this.form.formData.phone.error = false;
          }
          if (resp.messages.subject.status === true) {
            this.form.formData.subject.error = true;
          } else {
            this.form.formData.subject.error = false;
          }
          if (resp.messages.message.status === true) {
            this.form.formData.message.error = true;
          } else {
            this.form.formData.message.error = false;
          }
        } else {
          this.form.error = false;
          this.form.success = true;
          this.form.popUpMsg = `
            <p>
              Thank you for your message.
            </p>
            <p>
              A member of the team will be in touch shortly.
            </p>
          `;
        }
      })
      .catch(() => {
        this.form.error = true;
        this.form.success = false;
        this.form.popUpMsg = `
            <p>
              There was a problem submitting the form.
            </p>
          `;
      });
  }

  private closePopUp(): void {
    // if error message is shown, dont reset form when message is closed
    if (this.form.error) {
      this.form.error = false;
    } else {
      // if success message is shown reset the form when message is closed
      this.form.formData.name.value = "";
      this.form.formData.email.value = "";
      this.form.formData.phone.value = "";
      this.form.formData.subject.value = "";
      this.form.formData.message.value = "";
      this.form.success = false;
    }
  }
}
